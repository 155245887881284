
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// SweetAlert
@import "node_modules/sweetalert/dev/sweetalert.scss";

//Daterangepicker
@import "~daterangepicker/daterangepicker.css";

//Chartist
@import "_my_chartist-settings.scss";
@import "node_modules/chartist/dist/scss/chartist.scss";

//Select2
@import 'node_modules/select2/src/scss/core';

.navbar-default .navbar-nav > li > a
{
  color: $header-text-color;
}
#mtc-logo{
  max-width: 120px;
}

.userform{
  margin-top: 25px;
}

.maxwidth{
  max-width: 400px;
}

.navbar-default .navbar-nav
{
  margin-top: 10px;
}

.navbar-default .navbar-nav > .active > a
{
  background-color: $brand-primary;
  color: $header-text-color;
  font-weight: 700;
  text-decoration: underline;
}
.navbar-default .navbar-nav > .active > a:hover
{
  background-color: $brand-primary;
  color: $text-color;
  font-weight: 700;
  text-decoration: underline;
}
.navbar{
  background: $brand-primary;
  height: 5em;
  margin-bottom: 0px;
  border: 0px;
}

.divider-vertical {
  height: 30px;
  margin: 9px;
  border-left: 1px solid #F2F2F2;
  border-right: 1px solid #FFF;
}

.fullwidth{
  height: 450px;
  width:100%;
  display:inline-block;
  padding: 0px;
  margin: 0px;
  padding-top: 50px;
  background-color: $brand-primary;
  @media (max-width: 982px){
    height: 900px;
  }
}
.dashboard-graphs{
  padding-top: 3em;
  margin: 0 auto;
  color: #FFF
}
.main-container{
  margin-top: 22px;
  color: $actual-text-color;
}

#panel-container{
  margin-top: 22px;
  .h4{
    text-size: 15px;
  }
}

.reportcard_full_img{
    float: right;
    margin-right: 0;
    max-width: 200px;
    cursor: zoom-in;
}


.boot_line_sect{
  min-height: 77px;
  max-height: 77px;
  margin-top: 0px;
}

h2{
  font-size: 38px;
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}

.boot_line_sect_bot{
    min-height: 77px;
    max-height: 77px;
}

.color_prim_dark{
  color: darken($brand-primary, 20%);
}

.boot_pb{
  margin-top:8px;
  position: relative;
  height: 20px;
}


.largerbox{
  padding-bottom: 38px;
}
.padding-left-please{
  margin-left: 40px;
}


//CHARTIST STUFF
.ct-label {
    font-size: 12px;
}
.ct-legend {
    position: relative;
    z-index: 10;
    list-style-type: none;

    li {
        position: relative;
        padding-left: 23px;
        display: inline;
        padding-right: 10px;
    }

    li:before {
        width: 12px;
        height: 12px;
        position: absolute;
        left: 0;
        content: '';
        border: 3px solid transparent;
        border-radius: 2px;
    }

    li.inactive:before {
        background: transparent;
    }

    &.ct-legend-inside {
        position: absolute;
        top: 0;
        right: 0;
    }

    @for $i from 0 to length($ct-series-colors) {
        .ct-series-#{$i}:before {
            background-color: nth($ct-series-colors, $i + 1);
            border-color: nth($ct-series-colors, $i + 1);
        }
    }
}

.ct-axis-title{
}

.w-25 {
    width: 25%;
}

//tab-nav stuff
.panel.with-nav-tabs .panel-heading{
  padding: 5px 5px 0 5px;
}
.panel.with-nav-tabs .nav-tabs{
  border-bottom: none;
}
.panel.with-nav-tabs .nav-justified{
  margin-bottom: -1px;
}
/********************************************************************/
/*** PANEL DEFAULT ***/
.with-nav-tabs.panel-default .nav-tabs > li > a,
.with-nav-tabs.panel-default .nav-tabs > li > a:hover,
.with-nav-tabs.panel-default .nav-tabs > li > a:focus {
  color: #777;
}
.with-nav-tabs.panel-default .nav-tabs > .open > a,
.with-nav-tabs.panel-default .nav-tabs > .open > a:hover,
.with-nav-tabs.panel-default .nav-tabs > .open > a:focus,
.with-nav-tabs.panel-default .nav-tabs > li > a:hover,
.with-nav-tabs.panel-default .nav-tabs > li > a:focus {
  color: #777;
  background-color: #ddd;
  border-color: transparent;
}
.with-nav-tabs.panel-default .nav-tabs > li.active > a,
.with-nav-tabs.panel-default .nav-tabs > li.active > a:hover,
.with-nav-tabs.panel-default .nav-tabs > li.active > a:focus {
  color: #555;
  background-color: #fff;
  border-color: #ddd;
  border-bottom-color: transparent;
}

.print-image {
    width: 50%;

    @media (min-width: 1300px) {
        width: 35%;
    }

    @media (max-width: 768px) {
        width: 70%;
    }
}

.select2 {
    height: 36px;

    > .selection, .select2-selection {
        height: 100%;
    }
}