
// Body
$body-bg: #EFEFEF;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #318390;
$brand-info: #333333;
$brand-success: #318390;
$brand-warning: lighten($brand-primary,25%);
$brand-danger: #FE2851;

// Typography
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-sans-serif: "Myriad Pro", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #333333;
$header-text-color: #f3f3f3;
$actual-text-color: darken(#8F8E94, 10%);
// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);


// Panels
$panel-default-heading-bg: #fff;
